import { useLocalStorage } from '@BlackbirdHQ/ui-base';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { Pin as PinIcon, PinOff as PinOffIcon } from 'mdi-material-ui';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { LOCAL_STORAGE_PINNED } from '@/constants';

export interface Properties {
  identifier: string;
  storage: string;
}

const useStyles = makeStyles()((theme) => ({
  menuItemStyles: {
    padding: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menuItemIcon: {
    minWidth: theme.spacing(4),
  },
}));

const PinItem: FC<Properties> = ({ identifier, storage }) => {
  const [pinnedItems, setPinnedItems] = useLocalStorage<string[]>([LOCAL_STORAGE_PINNED, storage].join('_'), []);
  const [t] = useTranslation();
  const { classes } = useStyles();

  const isPinned = pinnedItems.includes(identifier);
  const onClick = useCallback(
    (event) => {
      event.preventDefault();

      if (isPinned) {
        setPinnedItems(pinnedItems.filter((id) => id !== identifier));
      } else {
        setPinnedItems([...pinnedItems, identifier]);
      }
    },
    [isPinned, pinnedItems],
  );

  return (
    <MenuItem onClick={onClick} className={classes.menuItemStyles}>
      <ListItemIcon className={classes.menuItemIcon}>{isPinned ? <PinOffIcon /> : <PinIcon />}</ListItemIcon>
      <Typography variant="inherit" noWrap>
        {isPinned ? t(['shared:unpin'], { defaultValue: 'Unpin' }) : t(['shared:pin'], { defaultValue: 'Pin' })}
      </Typography>
    </MenuItem>
  );
};

export default PinItem;
